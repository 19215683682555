import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import http from '@/services/http'
import CCSS from '@/Models/CCSS'
import Crm from '@/Models/Crm'
import TypeRateCCSS from '@/Models/TypeRateCCSS'
import TypeStateCCSS from '@/Models/TypeStateCCSS'
import * as Helpers from '@/helpers'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormCCSS extends Vue {
  @Prop({ required: false, default: null })
  objRow!: CCSS
  @Prop({ required: false, default: null })
  isAction!: string
  @Prop({ required: false, default: null })
  ownerMemberNumber!: number
  //el mae seleccionado
  objCCSS: CCSS = new CCSS()
  loading = false
  date: any = new Date()
  name = ''
  isFileEdit = false

  listMemberNumber: Array<Crm> = []
  listTypeRateCCSS: Array<TypeRateCCSS> = []
  listTypeStateCCSS: Array<TypeStateCCSS> = []
  selected!: any
  route!: string
  get is_login(): boolean {
    return authModule.isAuthenticated
  }
  mounted() {
    this.getNumberMember()
    this.getTypeRateCCSS()
    this.getTypeStateCCSS()
    if (this.objRow && this.isAction == 'edit') {
      if (this.objCCSS.member_number == undefined) {
        this.selected = this.objRow.member_number
      }
      this.$set(this, 'objCCSS', this.objRow)
      this.$set(
        this.objCCSS,
        'inputMemberNumber',
        this.objRow.member_number.client_name,
      )
    }
  }

  get filteredDataObj() {
    const filterData = this.listMemberNumber.filter(
      (option) =>
        option.client_name
          .toString()
          .toLowerCase()
          .indexOf(this.objCCSS.inputMemberNumber) >= 0,
    )
    return filterData
    //return filterData.map((x:any) =>  x.document_type)
  }
  async confirmation() {
    const ccss = this.CCSSForm(this.objCCSS)
    switch (this.isAction) {
      case 'add':
        await this.createObjCCSS(ccss)
        break
      case 'addDependent':
        await this.createObjCCSS(ccss)
        break
      case 'edit':
        await this.updateObjCCSS(ccss)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createObjCCSS(objCCSS: any) {
    try {
      await http.post(`/api/ccss/arcr_ccss/`, objCCSS)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      console.error(error.response.data)
      this.loading = false
      this.$buefy.toast.open({
        message: error.response.data,
        type: 'is-danger',
      })
    }
  }
  async updateObjCCSS(objCCSS: any) {
    console.log('editando', objCCSS)
    try {
      await http.put(`/api/ccss/arcr_ccss/${this.objRow.id}/`, objCCSS)
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      this.$buefy.toast.open({
        message: error.response.data,
        type: 'is-danger',
      })
    }
  }
  async getNumberMember() {
    this.loading = true
    try {
      const res = await http.get('api/crm/members_list/')
      if (!res.data) throw new Error(res.data)
      this.listMemberNumber = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }
  async getTypeStateCCSS() {
    try {
      const res = await http.get(`api/ccss/ccss_status/`)
      if (!res.data) throw new Error(res.data)
      this.listTypeStateCCSS = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async getTypeRateCCSS() {
    try {
      const res = await http.get(`/api/ccss/tariff_types/`)
      if (!res.data) throw new Error(res.data)
      this.listTypeRateCCSS = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }

  CCSSForm(obj: CCSS) {
    const CCSS = {
      id: obj.id,
      member_number_id: this.selected.member_number,
      start_date: Helpers.dateFormat_db(obj.start_date),
      insurance_number: obj.insurance_number,
      monthly_quota: obj.monthly_quota,
      status_id: obj.status,
      type_tariff_id: obj.tariff_type,
      account_balance: obj.account_balance,
      reported_salary: obj.reported_salary,
      automatic_charge_applies: obj.automatic_charge_applies ? 1 : 0,
      dependent: obj.dependent ? 1 : 0,
      associate_member_id: this.ownerMemberNumber,
    }
    return CCSS
  }
  close() {
    this.objCCSS = new CCSS()
    this.$emit('cleanForm')
  }
}
