var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"form"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Member number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.$t('Ex:') + ' Books S.A.',"keep-first":true,"open-on-focus":true,"field":"client_name","data":_vm.filteredDataObj,"clearable":true},on:{"select":function (option) { return (_vm.selected = option); }},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-content"},[_vm._v(" "+_vm._s(props.option.client_name)+" ")])])]}}],null,true),model:{value:(_vm.objCCSS.inputMemberNumber),callback:function ($$v) {_vm.$set(_vm.objCCSS, "inputMemberNumber", $$v)},expression:"objCCSS.inputMemberNumber"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Insurance number'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 000000',"type":"number"},model:{value:(_vm.objCCSS.insurance_number),callback:function ($$v) {_vm.$set(_vm.objCCSS, "insurance_number", $$v)},expression:"objCCSS.insurance_number"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Start date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objCCSS.start_date),callback:function ($$v) {_vm.$set(_vm.objCCSS, "start_date", $$v)},expression:"objCCSS.start_date"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Monthly fee'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 200',"type":"number"},model:{value:(_vm.objCCSS.monthly_quota),callback:function ($$v) {_vm.$set(_vm.objCCSS, "monthly_quota", $$v)},expression:"objCCSS.monthly_quota"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('State'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objCCSS.status),callback:function ($$v) {_vm.$set(_vm.objCCSS, "status", $$v)},expression:"objCCSS.status"}},_vm._l((_vm.listTypeStateCCSS),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.status)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Salary reported'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 630000',"type":"number"},model:{value:(_vm.objCCSS.reported_salary),callback:function ($$v) {_vm.$set(_vm.objCCSS, "reported_salary", $$v)},expression:"objCCSS.reported_salary"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Rate type'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Select value'),"expanded":""},model:{value:(_vm.objCCSS.tariff_type),callback:function ($$v) {_vm.$set(_vm.objCCSS, "tariff_type", $$v)},expression:"objCCSS.tariff_type"}},_vm._l((_vm.listTypeRateCCSS),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.tariff_type)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Account balance'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + ' 00.0',"type":"number"},model:{value:(_vm.objCCSS.account_balance),callback:function ($$v) {_vm.$set(_vm.objCCSS, "account_balance", $$v)},expression:"objCCSS.account_balance"}})],1)]}}],null,true)})],1),(_vm.isAction == 'edit')?_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Next payment date'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-datepicker',{attrs:{"placeholder":"12/12/12","icon":"calendar-today"},model:{value:(_vm.objCCSS.next_payment_date),callback:function ($$v) {_vm.$set(_vm.objCCSS, "next_payment_date", $$v)},expression:"objCCSS.next_payment_date"}})],1)]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Dependent'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-checkbox',{model:{value:(_vm.objCCSS.dependent),callback:function ($$v) {_vm.$set(_vm.objCCSS, "dependent", $$v)},expression:"objCCSS.dependent"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Automatic charge'),"type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-checkbox',{model:{value:(_vm.objCCSS.automatic_charge_applies),callback:function ($$v) {_vm.$set(_vm.objCCSS, "automatic_charge_applies", $$v)},expression:"objCCSS.automatic_charge_applies"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v(" Guardar ")]),_c('button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }